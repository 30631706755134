import Link from "next/link"
import { Menu } from "baseui/icon"
import { useRecoilState } from "recoil"
import { isShowSidebar } from "../../store/isShowSidebar.store"

function NavBar() {
  const [isActiveSidebar, setIsActiveSidebar] = useRecoilState(isShowSidebar)

  return (
    <div className="bg-white">
      <div className="relative w-full">
        <div className="flex items-center border-b border-gray-200 px-4 py-4">
          <button onClick={() => setIsActiveSidebar(!isActiveSidebar)}>
            <Menu size={24} />
          </button>
          <div className="ml-4 text-2xl font-semibold">
            <Link href="/orders" passHref>
              <a>
                <span className="hidden sm:inline">Today </span>Partner
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar

import React, { ReactNode } from "react"
import { useRecoilValue } from "recoil"
import { isShowSidebar } from "../../store/isShowSidebar.store"
import { classNames } from "@today/lib"
import useSideNavItem from "../../hooks/useSideNavItem"
import SideNavLink from "./SideNavLink"
import { useAuthentication } from "@today/auth"

function SideBar() {
  const isActiveSidebar = useRecoilValue(isShowSidebar)
  const { client, sideItemsByClient } = useSideNavItem()
  const { logout } = useAuthentication()

  return (
    <div
      className={classNames(
        "flex h-full w-56 flex-col overflow-y-scroll border-r pl-2 pt-2",
        isActiveSidebar ? "block" : "hidden"
      )}
    >
      <Title>
        <div className="overflow-hidden text-ellipsis whitespace-pre">{`${client?.name}님`}</div>
      </Title>
      {sideItemsByClient().map(({ title, itemId = "" }, i) => (
        <SideNavLink key={i} href={itemId}>
          <Title>{title}</Title>
        </SideNavLink>
      ))}
      <Title>
        <div onClick={() => logout()} className="cursor-pointer">
          {"로그아웃"}
        </div>
      </Title>
    </div>
  )
}

export default SideBar

function Title(props: { children: ReactNode }) {
  return <div className="px-5 py-4 font-semibold">{props.children}</div>
}

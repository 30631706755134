import { classNames } from "@today/lib"
import { PropsWithChildren, useState } from "react"

export function AttentionRibbon({
  className,
  children,
}: PropsWithChildren<{
  className: string
}>) {
  return (
    <>
      <div
        className={classNames(
          "py-1 text-center text-sm font-semibold text-white",
          className
        )}
      >
        {children}
      </div>
    </>
  )
}

import { useSWRWithAuth } from "@today/api"
import { Client } from "@today/api/taker"
import { useAuthentication, useUserInfo } from "@today/auth"
import { useRouter } from "next/router"
import { usePoints } from "../providers/PointsProvider"
import { FASTBOX_CLIENT_IDS } from "../components/dev-navigatation/clientIds.store"
import { Item as BaseItem } from "baseui/side-navigation"

export type SideItem = BaseItem & { activeOnSubPath?: boolean }

function useSideNavItem() {
  const router = useRouter()
  const { isAuthenticated } = useAuthentication()

  const { clientId, clientRoles } = useUserInfo()
  const { data: client } = useSWRWithAuth<Client>(
    clientId && `/api/clients/${clientId}`
  )
  const { isUsingPoints } = usePoints()
  const hasIncheonTemporaryRole = clientRoles.includes("incheon:temporary")

  function sideItemsByClient(): SideItem[] {
    const isSMEClient = client?.role === "SME"

    if (!isAuthenticated && router.pathname === "/incheon") {
      return [
        { title: "이용문의", itemId: "https://manual.amazing.today" },
        { title: "비밀번호 변경", itemId: "/incheon/reset-password" },
      ]
    }

    if (hasIncheonTemporaryRole) {
      const tempIncheonItems: SideItem[] = [
        { title: "사업장 정보 등록", itemId: "/incheon/config" },
      ]
      if (isSMEClient)
        tempIncheonItems.push({
          title: "공지사항",
          itemId: "https://manual.amazing.today/notice",
        })

      return tempIncheonItems.concat({
        title: "이용방법",
        itemId: "https://manual.amazing.today",
      })
    }

    const baseNavItems: SideItem[] = []
    if (isAuthenticated)
      baseNavItems.push({ title: "배송관리", itemId: "/orders" })
    if (isUsingPoints)
      baseNavItems.push({ title: "포인트 충전", itemId: "/points" })
    if (client?.enableBilling)
      baseNavItems.push({ title: "결제센터", itemId: "/billings" })
    if (clientId && FASTBOX_CLIENT_IDS.includes(clientId))
      baseNavItems.push({ title: "패스트박스", itemId: "/fastbox" })
    if (isSMEClient)
      baseNavItems.push({
        title: "공지사항",
        itemId: "https://manual.amazing.today/notice",
      })
    baseNavItems.push({
      title: "이용방법",
      itemId: isSMEClient
        ? "https://manual.amazing.today"
        : "https://manual.amazing.today/today/access",
    })
    if (isSMEClient)
      baseNavItems.push({
        title: "비밀번호 변경",
        itemId: "/incheon/reset-password",
      })

    return baseNavItems
  }

  return { client, sideItemsByClient }
}

export default useSideNavItem

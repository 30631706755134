export const FASTBOX_CLIENT_IDS = ["133449864958", "585576362589"]
export const 전통시장_당일_CLIENT_IDS = [
  // 열우물전통시장상인회
  "111023062886",
  "516835624343",
  // 계산시장상인회
  "149800045542",
  "596583941405",
  // 소래포구어시장상인회
  "142744887591",
  "573041461801",
  // 부평문화의시장상인회
  "162980577426",
  "580673658985",
  // 석바위시장상인회
  "198151707466",
  "578743879866",
]
export const 전통시장_익일_CLIENT_IDS = [
  // 열우물전통시장상인회
  "129121477054",
  "550729731557",
  // 계산시장상인회
  "117004514679",
  "504706660475",
  // 소래포구어시장상인회
  "165842670753",
  "586147074116",
  // 부평문화의시장상인회
  "137381706691",
  "548797062397",
  // 석바위시장상인회
  "192893259543",
  "539439591613",
]

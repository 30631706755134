import Link from "next/link"
import React, { ReactNode } from "react"

const HTTPS = "https"

type SideNavLinkProps = {
  href?: string
  children: ReactNode
}
function SideNavLink({ href = "", children }: SideNavLinkProps) {
  const isExternalUrl = href.startsWith(HTTPS)

  // 외부 링크인 경우 새 탭으로 열기
  if (isExternalUrl)
    return (
      <Link href={href} passHref>
        <a target="_blank" rel="noopener,noreferrer">
          {children}
        </a>
      </Link>
    )

  return (
    <Link href={href} passHref>
      <a>{children}</a>
    </Link>
  )
}

export default SideNavLink

import React from "react"
import { ENV } from "../../config"
import { AttentionRibbon } from "./AttentionRibbon"
import { useGetInvoker, useTakerAuth } from "@today/api"
import { Billing } from "@today/api/taker"
import { useUserInfo } from "@today/auth"
import { useRouter } from "next/router"
import Link from "next/link"
import {
  전통시장_당일_CLIENT_IDS,
  전통시장_익일_CLIENT_IDS,
} from "./clientIds.store"

const DevNavigation = () => {
  const router = useRouter()
  const { clientId } = useUserInfo()
  const taker = useTakerAuth()
  const { data: billingsData } = useGetInvoker<Billing, "billings">(
    clientId && taker.listClientBillings(clientId, { pageSize: 100 })
  )
  const hasPendingBillings = billingsData?.billings?.some(
    (billing) =>
      billing.billingType !== "TAX_INVOICE" &&
      (["READY", "WAITING_DEPOSIT"].includes(billing.state) ||
        (billing.state === "EXPIRED" &&
          !billing.virtualAccount?.renewedBillingId))
  )
  return (
    <>
      {ENV !== "prod" && (
        <AttentionRibbon className="bg-red-500">
          {`테스트용 환경(${ENV.toUpperCase()})입니다. `}
          <a className="underline" href="https://partner.amazing.today">
            {"운영 환경 바로가기"}
          </a>
        </AttentionRibbon>
      )}
      {hasPendingBillings &&
        (!router.pathname.startsWith("/billings") ? (
          <AttentionRibbon className="bg-red-500">
            {"미결제건이 있습니다. 결제센터에서 처리 부탁드립니다."}
            <Link href="/billings">
              <span className="underline">{"결제센터 바로가기"}</span>
            </Link>
          </AttentionRibbon>
        ) : (
          <AttentionRibbon className="bg-red-500">
            {"미결제건이 있습니다. 빠른 입금 부탁드립니다."}
          </AttentionRibbon>
        ))}
      {clientId && 전통시장_당일_CLIENT_IDS.includes(clientId) && (
        <AttentionRibbon className="bg-blue-500">
          {"서울/인천 당일배송"}
        </AttentionRibbon>
      )}
      {clientId && 전통시장_익일_CLIENT_IDS.includes(clientId) && (
        <AttentionRibbon className="bg-green-500">
          {"전국 택배"}
        </AttentionRibbon>
      )}
    </>
  )
}

export default DevNavigation
